/* eslint-disable prefer-const */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  FilterModel,
  GridType,
  IFedopsCustomParamsFull,
  IFilterSelectionValue,
  IGalleryControllerConfig,
  IGalleryStyleParams,
  ImageModeId,
  IProduct,
  IPropsInjectedByViewerScript,
  ISorting,
  ISortingOption,
  ITextsMap,
  LoadMoreType,
  PaginationType,
  PaginationTypeName,
  ProductIdToProductPageUrlMap,
} from '../types/galleryTypes';
import {
  BiEventParam,
  DEFAULT_COLLECTION_ID,
  Experiments,
  FedopsInteraction,
  sortOptions,
  translationPath,
} from '../constants';

import _ from 'lodash';
import {
  ActionStatus,
  AddToCartActionOption,
  APP_DEFINITION_ID,
  PageMap,
} from '@wix/wixstores-client-core/dist/es/src/constants';
import {FilterConfigsService} from '../services/FilterConfigsService';
import {FiltersService} from './FiltersService';
import {IControllerConfig, IWidgetControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types';
import {MultilingualService} from '@wix/wixstores-client-core/dist/src/multilingualService/multilingualService';
import {ProductsService} from '../services/ProductsService';
import {DefaultQueryParamKeys, GalleryQueryParamsService} from '../services/GalleryQueryParamsService';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {SortService} from '../services/SortService';
import {getStyleParamsWithDefaultsFunc} from '../getStyleParamsWithDefaultsFunc';
import {getStyleParamsWithDefaults} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/getStyleParamsWithDefaults';
import {getTranslations, isWorker} from '@wix/wixstores-client-core/dist/es/src/viewer-script/utils';
import {getInitialProductsCountToFetch} from './utils';

export class GalleryStore {
  private addedToCartStatus: {[p: string]: ActionStatus} = {};
  private currentPage: number = 1;
  private filterConfigsService: FilterConfigsService;
  private filtersService: FiltersService;
  private isFedopsReport: boolean = true;
  private mainCollectionId: string;
  private multilingualService: MultilingualService;
  private productPageSectionUrl: string;
  private readonly productsPerPage: number;
  private queryParamsService: GalleryQueryParamsService;
  private readonly fedopsLogger;
  private readonly publicData: IControllerConfig['publicData'];
  private readonly sortService: SortService;
  private translations;
  private readonly productsService: ProductsService;

  constructor(
    public styleParams: IGalleryStyleParams,
    private readonly config: IWidgetControllerConfig['config'],
    private readonly updateComponent: (props: Partial<IPropsInjectedByViewerScript>) => void,
    private readonly siteStore: SiteStore,
    private readonly compId: string,
    private readonly type: string,
    private readonly reportError: (e) => any
  ) {
    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: this.type,
    });

    if (isWorker()) {
      this.fedopsLogger.appLoadStarted();
    }
    //todo: COMPONENT === null is not tested, be this check can be removed after bolt will stop sending nulls https://wix.slack.com/archives/CAKBA7TDH/p1555852184003900
    if (this.config.publicData.COMPONENT === null || this.config.publicData.COMPONENT === undefined) {
      this.config.publicData.COMPONENT = {};
    }
    this.publicData = _.cloneDeep(this.config.publicData);
    this.sortService = new SortService();
    this.styleParams = styleParams;
    const isAutoGrid = this.styleParams.numbers.gallery_gridType === GridType.AUTO;

    const initialProductsCount = getInitialProductsCountToFetch(
      this.siteStore.isMobile(),
      this.siteStore.isEditorMode(),
      isAutoGrid,
      this.styleParams.numbers.galleryRows,
      this.styleParams.numbers.galleryColumns,
      this.styleParams.numbers.gallery_productsCount
    );

    this.productsService = new ProductsService(
      this.siteStore,
      initialProductsCount,
      'Grid Gallery',
      this.withOptions,
      this.siteStore.experiments.enabled(Experiments.subscriptionPlans),
      this.fedopsLogger
    );
    this.productsPerPage = this.productsService.getProductPerPage();
    this.handleCurrencyChange();
  }

  private get withOptions(): boolean {
    return (
      this.siteStore.experiments.enabled(Experiments.ProductOptionsInGalleryComp) &&
      this.styleParams.booleans.gallery_showProductOptionsButton
    );
  }

  private handleCurrencyChange() {
    let currency = this.siteStore.location.query.currency;
    this.siteStore.location.onChange(async () => {
      if (currency !== this.siteStore.location.query.currency) {
        currency = this.siteStore.location.query.currency;
        await this.setInitialState();
      }
    });
  }

  private getTranslation() {
    return this.translations
      ? Promise.resolve(this.translations)
      : getTranslations(translationPath(this.siteStore.baseUrls.galleryBaseUrl, this.siteStore.locale));
  }

  public async setInitialState(): Promise<any> {
    let data, translations, url, sorting: ISortingOption, products, limit;

    if (this.isAutoGrid()) {
      limit = this.styleParams.numbers.gallery_productsCount;
    }

    this.queryParamsService = new GalleryQueryParamsService(this.siteStore.location);
    if (this.siteStore.location.query.sort) {
      sorting = this.getSortFromQueryParam();
    }
    if (this.siteStore.location.query.page) {
      limit = this.getProductsLimitByPageFromQueryParam(this.productsService.getProductPerPage());
      this.currentPage = +this.queryParamsService.getQueryParam('page');
    }

    [translations, data, {url}] = await Promise.all([
      this.getTranslation(),
      this.productsService.getInitialData({
        externalId: this.config.externalId,
        compId: this.compId,
        sort: this.sortService.getSortDTO(sorting),
        filters: null,
        limit,
        offset: 0,
      }),
      this.siteStore.getSectionUrl(PageMap.PRODUCT),
    ]).catch(this.reportError);

    sorting && this.sortService.setSelectedSort(sorting.id);

    products = data.catalog.category.productsWithMetaData.list;
    this.translations = translations;
    this.productPageSectionUrl = url;
    this.mainCollectionId = this.productsService.getMainCollectionId();

    this.multilingualService = new MultilingualService(
      this.publicData.COMPONENT,
      data.appSettings.widgetSettings,
      this.siteStore.getMultiLangFields(),
      this.siteStore.locale
    );

    this.filterConfigsService = new FilterConfigsService(
      data.appSettings.widgetSettings.FILTERS,
      this.publicData.COMPONENT.FILTERS && this.publicData.COMPONENT.FILTERS.data,
      this.styleParams.booleans,
      this.multilingualService,
      translations
    );

    this.filtersService = new FiltersService(this.siteStore, this.mainCollectionId, this.filterConfigsService);
    let filterModels: FilterModel[] | [] = [];
    if (this.shouldShowFilters()) {
      filterModels = await this.fetchFilters();

      filterModels = this.getFiltersFromQueryParams(filterModels);

      this.productsService.updateFiltersAndSort(this.filtersService.getFilterDTO(), sorting);

      data = await this.productsService.getInitialData({
        externalId: this.config.externalId,
        compId: this.compId,
        sort: this.sortService.getSortDTO(sorting),
        filters: this.filtersService.getFilterDTO(),
        limit,
        offset: 0,
      });
      products = data.catalog.category.productsWithMetaData.list;
    }

    if (this.loadMoreType() === LoadMoreType.PAGINATION && this.queryParamsService.getQueryParam('page')) {
      products = await this.loadProductsByPage(this.currentPage);
    }

    let isFirstPage = true;

    if (this.currentPage > 1) {
      isFirstPage = false;
    }

    this.updateComponent({
      ...this.getPropsToInject(products, filterModels),
      isFirstPage,
    });

    if (this.siteStore.isSSR()) {
      this.fedopsLogger.appLoaded();
    }
  }

  public onAppLoaded(): void {
    if (this.isFedopsReport) {
      this.fedopsLogger.appLoaded(this.getFedopsCustomParams());
      this.isFedopsReport = false;

      this.siteStore.biLogger.exposureEventForTests({
        testName: 'Gallery loaded',
        is_eligible: this.shouldShowFilters(),
        isMobileFriendly: this.siteStore.isMobileFriendly,
      });
    }
  }

  private getFedopsCustomParams() {
    const page_load_type = {
      [LoadMoreType.BUTTON]: BiEventParam.LoadMore,
      [LoadMoreType.PAGINATION]: BiEventParam.Pagination,
      [LoadMoreType.INFINITE]: BiEventParam.InfiniteScroll,
    }[this.styleParams.numbers.gallery_loadMoreProductsType];

    return {
      customParams: JSON.stringify({
        filter_types:
          (this.styleParams.booleans.galleryShowFilters &&
            this.filtersService
              .getFilterModels()
              .map((m) => m.filterType)
              .join(',')) ||
          null,
        is_add_to_cart_hover:
          this.styleParams.booleans.gallery_showAddToCartButton &&
          this.styleParams.booleans.gallery_addToCartButtonShowOnHover,
        is_collection: this.mainCollectionId !== DEFAULT_COLLECTION_ID,
        is_full_width: this.styleParams.booleans.full_width,
        on_hover: this.styleParams.fonts.gallery_hoverType.value,
        page_load_type,
        products_image_resize: this.styleParams.numbers.gallery_imageMode === ImageModeId.Crop ? 'crop' : 'fit',
        products_text_align: this.styleParams.fonts.gallery_paginationAlignment.value,
        show_add_to_cart: this.styleParams.booleans.gallery_showAddToCartButton,
        show_divider: this.styleParams.booleans.gallery_showDividers,
        show_filters: this.styleParams.booleans.galleryShowFilters,
        show_price: this.styleParams.booleans.gallery_showPrice,
        show_product_name: this.styleParams.booleans.gallery_showProductName,
        show_quantity:
          this.styleParams.booleans.gallery_showAddToCartButton &&
          this.styleParams.booleans.gallery_showAddToCartQuantity,
        show_quick_view: this.styleParams.booleans.showQuickView,
        show_sorting: this.styleParams.booleans.galleryShowSort,
        store_id: this.siteStore.storeId,
      } as IFedopsCustomParamsFull),
    };
  }

  private readonly shouldShowClearFilters = () => {
    return this.siteStore.isMobile() || this.filtersService.shouldShowClearFilters();
  };

  private getPropsToInject(products: IProduct[], filterModels: FilterModel[] | []): IPropsInjectedByViewerScript {
    const props = {
      ...this.getComputedProps(products),
      addedToCartStatus: this.addedToCartStatus,
      clearFilters: this.clearFilters.bind(this),
      cssBaseUrl: this.siteStore.baseUrls.galleryBaseUrl,
      filterModels,
      filterProducts: this.filterProducts.bind(this),
      filterProductsOnMobile: this.filterProductsOnMobile.bind(this),
      handleAddToCart: this.handleAddToCart.bind(this),
      sendSortClickBiEvent: this.sendSortClickBiEvent.bind(this),
      handlePagination: this.handlePagination.bind(this),
      handleProductItemClick: this.handleProductItemClick.bind(this),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      hasSelectedFilters: this.hasSelectedFilters(),
      isAutoGrid: this.isAutoGrid(),
      isFirstPage: true,
      isInteractive: this.siteStore.isInteractive(),
      isLiveSiteMode: this.siteStore.isSiteMode(),
      isLoaded: true,
      isMobile: this.siteStore.isMobile(),
      isPreviewMode: this.siteStore.isPreviewMode(),
      isRTL: this.siteStore.isRTL(),
      loadMoreProducts: this.loadMoreProducts.bind(this),
      loadMoreType: this.loadMoreType(),
      loading: false,
      mainCollectionId: this.mainCollectionId,
      numberOfSelectedFilterTypes: 0,
      onAppLoaded: this.onAppLoaded.bind(this),
      openQuickView: this.openQuickView.bind(this),
      paginationMode: this.getPaginationMode(),
      ravenUserContextOverrides: {id: this.siteStore.storeId, uuid: this.siteStore.uuid},
      selectedSort: this.sortService.getSelectedSort(),
      shouldShowAddToCartSuccessAnimation: this.getAddToCartAction() === AddToCartActionOption.NONE,
      shouldShowClearFilters: this.shouldShowClearFilters(),
      shouldShowMobileFiltersModal: false,
      shouldShowSort: this.shouldShowSort(),
      shouldShowProductOptions: this.withOptions,
      productsVariantsAvailabilityMap: this.withOptions
        ? this.productsService.productsOptionsService.getVariantsAvailabilityMap()
        : {},
      showShowLightEmptyState: this.productsService.hideGallery,
      sortProducts: this.sortProducts.bind(this),
      sortingOptions: this.getSortingOptions(),
      textsMap: this.getTextsMap(),
      toggleFiltersModalVisibility: this.toggleFiltersModalVisibility.bind(this),
      totalProducts: this.productsService.totalCount,
      updateAddToCartStatus: this.updateAddToCartStatus.bind(this),
      experiments: {},
      handleProductsOptionsChange: this.handleOptionSelectionsChange.bind(this),
      productsRequestInProgress: false,
    };

    if (this.siteStore.experiments.enabled('specs.stores.GalleryScopeStaticCss')) {
      (props as any).prefixStaticCss = true;
    }

    return props;
  }

  private getTextsMap(): ITextsMap {
    return {
      addToCartContactSeller: this.translations['gallery.contactSeller.button'],
      addToCartOutOfStock:
        this.multilingualService.get('gallery_oosButtonText') || this.translations['gallery.outOfStock.button'],
      addToCartSuccessSR: this.translations['gallery.sr.addToCartSuccess'],
      allCollectionsFilterButtonText: this.translations['filter.CATEGORY_ALL'],
      clearFiltersButtonText: this.translations['filter.CLEAN_ALL'],
      digitalProductBadgeAriaLabelText: this.translations['sr.digitalProduct'],
      galleryAddToCartButtonText:
        this.multilingualService.get('gallery_addToCartText') || this.translations['gallery.addToCart.button'],
      galleryRegionSR: this.translations['sr.region.GALLERY'],
      filtersSubmitButtonText: this.translations['gallery.mobile.filters.buttonApply'],
      filtersTitleText: this.multilingualService.get('FILTERS_MAIN_TITLE') || this.translations['filter.MAIN_TITLE'],
      loadMoreButtonText: this.multilingualService.get('LOAD_MORE_BUTTON') || this.translations.LOAD_MORE_BUTTON,
      mobileFiltersButtonText: this.translations['gallery.mobile.filters.title.button'],
      noProductsFilteredMessageText: this.translations.NO_PRODUCTS_FILTERED_MESSAGE_MAIN,
      noProductsMessageText: this.translations.NO_PRODUCTS_MESSAGE_MAIN,
      sortOptionNewestText: this.translations['sort.NEWEST'],
      sortOptionLowPriceText: this.translations['sort.PRICE_LOW'],
      sortOptionHighPriceText: this.translations['sort.PRICE_HIGH'],
      sortOptionNameAZText: this.translations['sort.NAME_AZ'],
      sortOptionNameZAText: this.translations['sort.NAME_ZA'],
      sortTitleText: this.multilingualService.get('SORTING_MAIN_TITLE') || this.translations.SORT_BY,
      productOutOfStockText:
        this.multilingualService.get('gallery_oosButtonText') || this.translations.OUT_OF_STOCK_LABEL,
      productPriceBeforeDiscountSR: this.translations['sr.PRODUCT_PRICE_BEFORE_DISCOUNT'],
      productPriceAfterDiscountSR: this.translations['sr.PRODUCT_PRICE_AFTER_DISCOUNT'],
      productPriceWhenThereIsNoDiscountSR: this.translations['sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT'],
      quickViewButtonText: this.translations.QUICK_VIEW,
      quantityAddSR: this.translations['gallery.sr.addQty'],
      quantityChooseAmountSR: this.translations['gallery.sr.chooseQty'],
      quantityRemoveSR: this.translations['gallery.sr.removeQty'],
      quantityInputSR: this.translations['gallery.sr.quantity'],
      announceFiltersUpdate: this.translations['sr.ANNOUNCE_FOUND_ITEMS_ON_FILTERS_UPDATE'],
      quantityMaximumAmountSR: this.translations['gallery.exceedsQuantity.error'],
      quantityTotalSR: this.translations['gallery.sr.totalQty'],
      quantityMinimumAmountSR: this.translations['gallery.minimumQuantity.error'],
    };
  }

  private generateProductIdToProductPageUrlMap(products: IProduct[]): ProductIdToProductPageUrlMap {
    return products.reduce((acc, product) => {
      acc[product.id] = `${this.productPageSectionUrl}/${product.urlPart}`;
      return acc;
    }, {});
  }

  private async filterProducts(filterId: number, selectionValue: IFilterSelectionValue) {
    this.fedopsLogger.interactionStarted(FedopsInteraction.Filter);

    this.filtersService.updateActiveFilterOption(filterId, selectionValue);

    this.queryParamsService.updateQueryParamsByFilters(
      filterId,
      this.filtersService.getFilterModels(),
      this.mainCollectionId
    );

    if (this.siteStore.isMobile()) {
      this.updateComponent({
        filterModels: this.filtersService.getFilterModels(),
        hasSelectedFilters: this.hasSelectedFilters(),
      });
      this.fedopsLogger.interactionEnded(FedopsInteraction.Filter);
      return;
    }

    this.siteStore.biLogger.clickToChangeGalleryFiltersSf({
      filterType: this.filtersService.getFilterModel(filterId).filterType,
    });

    await this.updateComponentWithFilteredProducts();
    this.fedopsLogger.interactionEnded(FedopsInteraction.Filter);
  }

  private async filterProductsOnMobile() {
    this.fedopsLogger.interactionStarted(FedopsInteraction.MobileFilter);
    this.siteStore.biLogger.galleryClickApplyFilter({
      filterTypes: this.filtersService.getSelectedFilterTypes().toString(),
    });

    this.filtersService.updateSnapshotWithActiveOptions();
    this.updateComponent({
      loading: true,
      numberOfSelectedFilterTypes: this.filtersService.getSelectedFilterTypes().length,
    });

    await this.updateComponentWithFilteredProducts();
    this.updateComponent({loading: false, shouldShowMobileFiltersModal: false});
    this.fedopsLogger.interactionEnded(FedopsInteraction.MobileFilter);
  }

  private async clearFilters() {
    this.filtersService.resetFilters();
    this.queryParamsService.clearAllQueryParamsFilters(
      this.filtersService.getFilterModels().map((filterModel) => filterModel.title)
    );

    this.siteStore.biLogger.galleryClickClearAllFilters({});

    if (this.siteStore.isMobile()) {
      this.updateComponent({
        filterModels: this.filtersService.getFilterModels(),
        hasSelectedFilters: this.hasSelectedFilters(),
      });
    } else {
      await this.updateComponentWithFilteredProducts();
    }
  }

  private toggleFiltersModalVisibility(show: boolean) {
    if (show) {
      this.filtersService.updateSnapshotWithActiveOptions();
      this.siteStore.biLogger.galleryClickFilter({});
    } else {
      this.filtersService.updateActiveOptionsWithSnapshot();
    }

    this.updateComponent({
      shouldShowMobileFiltersModal: show,
      filterModels: this.filtersService.getFilterModels(),
      hasSelectedFilters: this.hasSelectedFilters(),
    });
  }

  private async updateComponentWithFilteredProducts() {
    const filterDTO = this.filtersService.getFilterDTO();
    const nextProducts = await this.productsService.filterProducts(
      filterDTO,
      this.filtersService.getCollectionIdsFilterDTO()
    );

    this.currentPage = 1;
    this.queryParamsService.updatePageQueryParam();

    this.updateComponent({
      ...this.getComputedProps(nextProducts),
      filterModels: this.filtersService.getFilterModels(),
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      hasSelectedFilters: this.hasSelectedFilters(),
      shouldShowClearFilters: this.shouldShowClearFilters(),
      shouldShowSort: this.shouldShowSort(),
      totalProducts: this.productsService.totalCount,
    });
  }

  private hasSelectedFilters(): boolean {
    return this.filtersService.shouldShowClearFilters();
  }

  private readonly loadMoreType = (): LoadMoreType => {
    if (
      this.styleParams.numbers.gallery_loadMoreProductsType === LoadMoreType.INFINITE &&
      !this.siteStore.experiments.enabled(Experiments.GalleryInfiniteScroll)
    ) {
      return LoadMoreType.BUTTON;
    }
    return this.styleParams.numbers.gallery_loadMoreProductsType;
  };

  private async loadMoreProducts(visibleProducts: number, maxProductsPerPage: number) {
    this.updateComponent({
      productsRequestInProgress: true,
    });

    const fedopsInteraction =
      this.loadMoreType() === LoadMoreType.BUTTON ? FedopsInteraction.LoadMore : FedopsInteraction.InfiniteScroll;
    this.fedopsLogger.interactionStarted(fedopsInteraction);
    this.siteStore.biLogger.clickLoadMoreInGallerySf({
      ...this.getBICollection(),
      type: this.loadMoreType() === LoadMoreType.BUTTON ? BiEventParam.LoadMore : BiEventParam.InfiniteScroll,
    });
    this.productsService.setProductsPerPage(maxProductsPerPage);
    this.currentPage++;
    this.queryParamsService.updatePageQueryParam(this.currentPage);

    const nextProducts = await this.productsService.loadMoreProducts(visibleProducts);
    if (nextProducts === null) {
      this.updateComponent({
        hasMoreProductsToLoad: false,
        productsRequestInProgress: false,
      });
      return;
    }
    this.updateComponent({
      ...this.getComputedProps(nextProducts),
      focusedProductIndex: visibleProducts,
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      productsRequestInProgress: false,
    });

    this.fedopsLogger.interactionEnded(fedopsInteraction);
  }

  private readonly hasOptions = (product: IProduct) => !!product.options.length;

  private readonly getAddToCartAction = () => this.styleParams.numbers.gallery_addToCartAction;

  private readonly updateAddToCartStatus = (productId: string, status: ActionStatus) => {
    this.addedToCartStatus = {
      ...this.addedToCartStatus,
      [productId]: status,
    };

    this.updateComponent({addedToCartStatus: this.addedToCartStatus});
  };

  private handleAddToCart({productId, index, quantity}: {productId: string; index: number; quantity: number}) {
    this.productsService
      .addToCart({
        productId,
        index,
        compId: this.compId,
        externalId: this.config.externalId,
        quantity,
        action: this.getAddToCartAction(),
      })
      .then(() => this.updateAddToCartStatus(productId, ActionStatus.SUCCESSFUL));
  }

  private handleOptionSelectionsChange(params: {productId: string; selectionIds: number[]}) {
    this.productsService.handleProductsOptionsChange(params);
    this.updateComponent({
      productsVariantsAvailabilityMap: this.productsService.productsOptionsService.getVariantsAvailabilityMap(),
    });
  }

  private readonly getSortFromQueryParam = (): ISortingOption => {
    return this.sortService.getSort(this.queryParamsService.getQueryParam('sort'));
  };

  private readonly getProductsLimitByPageFromQueryParam = (productPerPage: number): number => {
    return parseInt(this.queryParamsService.getQueryParam(DefaultQueryParamKeys.Page), 10) * productPerPage;
  };

  private readonly getFiltersFromQueryParams = (filterModels: FilterModel[]) => {
    const queryParamsFilters = this.queryParamsService.getFiltersFromQueryParams(filterModels);
    if (queryParamsFilters) {
      this.filtersService.updateActiveFilterOptionsByQueryParams(queryParamsFilters);
    }
    return this.filtersService.getFilterModels();
  };

  private async sortProducts(sorting: ISorting) {
    this.fedopsLogger.interactionStarted(FedopsInteraction.Sort);
    this.queryParamsService.updateQueryParamsBySort(sorting);

    const selectedSort = this.sortService.setSelectedSort(sorting.id);
    this.siteStore.biLogger.sortGallerySf({sortDir: sorting.direction, method: sorting.id.split('_')[0]});
    const newProducts = await this.productsService.sortProducts(sorting.field ? sorting : null);
    this.updateComponent({
      currentPage: this.currentPage = 1,
      hasMoreProductsToLoad: this.productsService.hasMoreProductsToLoad(),
      isFirstPage: false,
      productIdToProductPageUrlMap: this.generateProductIdToProductPageUrlMap(newProducts),
      products: newProducts,
      selectedSort,
    });
    this.fedopsLogger.interactionEnded(FedopsInteraction.Sort);
  }

  private shouldShowSort() {
    const {
      galleryShowSort,
      gallerySortNewest,
      gallerySortPriceAsc,
      gallerySortPriceDes,
      gallerySortNameAsc,
      gallerySortNameDes,
    } = this.styleParams.booleans;
    return (
      galleryShowSort &&
      (gallerySortNewest || gallerySortPriceAsc || gallerySortPriceDes || gallerySortNameAsc || gallerySortNameDes) &&
      this.productsService.products.length > 0
    );
  }

  private openQuickView({productId, index}: {productId: string; index: number}) {
    this.productsService.quickViewProduct(productId, index, this.compId, this.config.externalId);
  }

  private handleProductItemClick({
    biData: {productId, index},
  }: {
    biData: {
      productId: string;
      index: number;
    };
  }) {
    const product = this.productsService.getProduct(productId);

    this.productsService.storeNavigation(this.siteStore.siteApis.currentPage.id);

    this.siteStore.biLogger.clickOnProductBoxSf({
      productId,
      hasRibbon: !!product.ribbon,
      hasOptions: this.hasOptions(product),
      index,
      productType: product.productType,
    });
    this.productsService.sendClickTrackEvent(product, index);

    this.siteStore.navigate(
      {
        sectionId: PageMap.PRODUCT,
        state: product.urlPart,
        queryParams: undefined,
      },
      this.siteStore.isPreviewMode() &&
        this.siteStore.experiments.enabled('specs.stores.HackGalleryNavigationInPreview')
    );
  }

  private fetchFilters(): Promise<FilterModel[]> {
    return this.filtersService.fetchFilters();
  }

  private shouldShowFilters(): boolean {
    return this.styleParams.booleans.galleryShowFilters && this.filterConfigsService.shouldShowFilters();
  }

  private getSortingOptions(): ISortingOption[] {
    return sortOptions.filter((o) => this.styleParams.booleans[o.settingsShouldDisplayKey] !== false);
  }

  private sendSortClickBiEvent() {
    this.siteStore.biLogger.galleryClickSortBy({});
  }

  private updatePublicData(newPublicData: IGalleryControllerConfig['publicData']) {
    Object.keys(newPublicData.COMPONENT).forEach((key) => {
      this.publicData.COMPONENT[key] = newPublicData.COMPONENT[key];
    });
  }

  public async updateState(
    newStyleParams: IGalleryStyleParams,
    newPublicData: IGalleryControllerConfig['publicData'] & {appSettings?: any}
  ): Promise<void> {
    const nextStyleParams = getStyleParamsWithDefaults(newStyleParams, () =>
      getStyleParamsWithDefaultsFunc({
        style: {styleParams: newStyleParams},
        dimensions: undefined,
      })
    );

    const shouldUpdateWithOptions =
      this.styleParams.booleans.gallery_showProductOptionsButton !==
      nextStyleParams.booleans.gallery_showProductOptionsButton;
    const shouldFetchProducts =
      shouldUpdateWithOptions ||
      this.styleParams.numbers.gallery_productsCount !== nextStyleParams.numbers.gallery_productsCount;

    this.updatePublicData(newPublicData);
    this.styleParams = {...nextStyleParams};

    if (newPublicData.appSettings) {
      this.multilingualService.setWidgetSettings(newPublicData.appSettings);
      if (newPublicData.appSettings.FILTERS) {
        this.filterConfigsService.setFilterConfigDTOs(newPublicData.appSettings.FILTERS);
        this.filtersService.deleteFilterModels();
      }
    }

    if (!this.shouldShowFilters()) {
      this.filtersService.deleteFilterModels();
      this.updateComponent({filterModels: []});
    } else if (this.filtersService.getFilterModels().length === 0) {
      const filterModels = await this.filtersService.fetchFilters();
      this.updateComponent({filterModels});
    }

    const nextProps: Partial<IPropsInjectedByViewerScript> = {
      isAutoGrid: this.isAutoGrid(),
      loadMoreType: this.loadMoreType(),
      shouldShowAddToCartSuccessAnimation: this.getAddToCartAction() === AddToCartActionOption.NONE,
      shouldShowSort: this.shouldShowSort(),
      sortingOptions: this.getSortingOptions(),
      textsMap: this.getTextsMap(),
    };

    if (shouldUpdateWithOptions) {
      this.updateWithOptions();
    }

    if (shouldFetchProducts) {
      nextProps.products = await this.productsService.loadProducts(0, this.productsService.getProductPerPage());
    }

    this.updateComponent(nextProps);
  }

  private updateWithOptions() {
    this.productsService.setWithOptions(this.withOptions);
  }

  private getBICollection() {
    const collectionId = this.productsService.getMainCollectionId();
    return collectionId === DEFAULT_COLLECTION_ID ? {} : {categoryId: collectionId};
  }

  private async loadProductsByPage(page: number) {
    const from = this.productsPerPage * (page - 1);
    const to = from + this.productsPerPage;
    return this.productsService.loadProducts(from, to);
  }

  private async handlePagination(page: number) {
    this.fedopsLogger.interactionStarted(FedopsInteraction.Pagination);
    const products = await this.loadProductsByPage(page);
    this.currentPage = page;
    this.siteStore.biLogger.clickLoadMoreInGallerySf({...this.getBICollection(), type: BiEventParam.Pagination});
    this.queryParamsService.updatePageQueryParam(page);
    this.updateComponent(this.getComputedProps(products));
    this.fedopsLogger.interactionEnded(FedopsInteraction.Pagination);
  }

  private getComputedProps(products: IProduct[]) {
    return {
      currentPage: this.currentPage,
      isFirstPage: false,
      productIdToProductPageUrlMap: this.generateProductIdToProductPageUrlMap(products),
      products,
    };
  }

  private readonly isEditorX = () => {
    return this.styleParams.booleans.responsive === true;
  };

  private readonly isAutoGrid = () => {
    if (this.isEditorX()) {
      return this.styleParams.numbers.gallery_gridType === GridType.AUTO;
    }

    if (this.siteStore.isMobile()) {
      return false;
    }

    return (
      this.siteStore.experiments.enabled('specs.stores.GalleryAutoGrid') &&
      this.styleParams.numbers.gallery_gridType === GridType.AUTO
    );
  };

  private getPaginationMode(): PaginationTypeName {
    return this.siteStore.isMobile() || this.styleParams.numbers.gallery_paginationFormat === PaginationType.COMPACT
      ? 'compact'
      : 'pages';
  }
}
